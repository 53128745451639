import { AlertColor, Backdrop, Box, CircularProgress, Snackbar, SnackbarOrigin } from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import Footer from "components/footer/Footer";
import HeaderSection from "pages/index/HeaderSection";
import ResultSection from "pages/index/ResultSection";
import MobileImageKeywordSearchSection from "pages/mobileIndex/MobileImageKeywordSearchSection";
import MobileImageSearchSection from "pages/mobileIndex/MobileImageSearchSection";
import MobileImageURLSearchSection from "pages/mobileIndex/MobileImageURLSearchSection";
import MobileMainSection from "pages/mobileIndex/MobileMainSection";
import { useEffect, useState } from "react";
import TagManager from 'react-gtm-module';
import { Helmet } from 'react-helmet-async';
import { useRecoilValue } from "recoil";
import { LoadingState } from 'recoil/atom/LoadingState';
import Colors from "utils/Colors";
import NoticeDialog from './pages/notice/NoticeDialog';
import NoticeBanner from './pages/notice/NoticeBanner';

export interface MessageState extends SnackbarOrigin {
  open: boolean;
  message: string;
  severity: AlertColor;
  vertical: 'top',
  horizontal: 'center',
}

function App() {
  const loading = useRecoilValue(LoadingState);
  const [messageState, setMessageState] = useState<MessageState>({
    open: false,
    message: '',
    severity: 'success',
    vertical: 'top',
    horizontal: 'center',
  });

  const handleCloseSnackbar = () => {
    setMessageState({ ...messageState, open: false });
  };

  const handleMessage = (success: boolean, message: string) => {
    setMessageState({
      ...messageState,
      message,
      severity: success ? 'success' : 'error',
      open: true
    });
  };

  useEffect(() => {
    TagManager.initialize({
      gtmId: process.env.REACT_APP_GTsM_ID,
    });

  }, []);
  const [open, setOpen] = useState(false);

// 공지사항 열기
  const handleOpen = () => setOpen(true);

// 공지사항 닫기
  const handleClose = () => setOpen(false);

// 필요한 경우 자동으로 표시
//   useEffect(() => {
//     setOpen(true);
//   }, []);
  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_TITLE}</title>
      </Helmet>

      {/* 노티스 배너 추가 */}
      <NoticeBanner onShowNotice={handleOpen} />

      {/* NoticeDialog 추가 */}
      <NoticeDialog
        open={open}
        onClose={handleClose}
        mobile={false}
      />

      <Box
        sx={{ display: { xs: 'none', sm: 'block', md: 'block', lg: 'block', xl: 'block' } }}
        style={{ minWidth: 800 }}>
        <HeaderSection onMessage={handleMessage} />
        <ResultSection />
        <Footer />
      </Box>

      <Box
        sx={{ display: { xs: 'block', sm: 'none' } }}
        style={{ width: '100%', }}
      >
        <MobileMainSection />
        <MobileImageSearchSection />
        <MobileImageURLSearchSection colored={true} />
        <MobileImageKeywordSearchSection />
      </Box>

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: messageState.vertical, horizontal: messageState.horizontal }}
        open={messageState.open}
        onClose={handleCloseSnackbar}
        key={'message'}
      >
        <MuiAlert
          elevation={6}
          onClose={handleCloseSnackbar}
          variant={'filled'}
          severity={messageState.severity}
          style={{ whiteSpace: 'pre-line' }}
          sx={{ width: '100%' }}>
          {messageState.message || '오류가 발생했습니다. 잠시 후 다시 시도해주세요.'}
        </MuiAlert>
      </Snackbar>

      <Backdrop
        sx={{ color: Colors.white, zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}>
        <CircularProgress color={'inherit'} />
      </Backdrop>
    </>
  );
}

export default App;
