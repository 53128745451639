import React, { forwardRef, useEffect, useState } from 'react';
import { Dialog, AppBar, Toolbar, IconButton, Typography, Link, Slide, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { TransitionProps } from '@mui/material/transitions';

const PrivacyContent = () => {
  return (
    <Box sx={{
      p: 4,
      maxWidth: 800,
      mx: 'auto',
      letterSpacing: 0,
      wordSpacing: 0,
      '& h6': {
        letterSpacing: 0,
        wordSpacing: 0,
        mb: 1.5,
        fontSize: 12,
        fontWeight: 500
      },
      '& p, & li': {
        letterSpacing: 0,
        wordSpacing: 0,
        fontSize: 12,
        lineHeight: 1.6
      }
    }}>
      <Typography sx={{ fontSize: '16px', mb: 3 }}>
        폰트폰트 개인정보 처리방침
      </Typography>

      <Box sx={{ mb: 1, color: 'black', fontSize: '12px' }}>
        <Typography>
          ㈜산돌스퀘어(이하 '회사')은 이용자의 개인정보보호를 중요시하며, '정보통신망 이용촉진 및 정보보호'에 관한 법률 및 개인정보보호법을 준수하고 있습니다. 회사는 본 개인정보처리방침을 통하여 이용자의 개인정보를 어떠한 용도와 방식으로 이용하고 있으며, 개인정보보호를 위해 어떠한 조치를 취하고 있는지 알려 드립니다. 본 개인정보처리방침은 관련 법령 및 지침의 변경이나 회사의 내부 방침 변경 등으로 인해 변경될 수 있으며, 변경 시 회사는 변경 이유 및 내용에 대해 애플리케이션 화면 내에 게시하거나 이용자에게 고지합니다.
        </Typography>
      </Box>

      <Box sx={{ mb: 1, color: 'black', fontSize: '12px' }}>
        <Typography>
          1. 수집하는 개인정보의 항목 및 수집방법
        </Typography>
        <Typography>
          폰트폰트는 개인을 식별할 수 있는 개인정보를 수집하지 않습니다. 단, 광고사의 SDK에 의해 광고 식별자인 광고 ID가 수집될 수 있습니다. 광고 식별자는 미 영구적이고 비 개인적인 식별자로써 개인을 식별할 수 없으며 기기의 설정 변경을 통해 사용을 거부할 수 있습니다.
        </Typography>
        <Typography>
          본 서비스에는 제3자의 서비스인 Google Firebase, Analytics가 포함되어 있습니다. 이러한 제3자들은 각각의 서비스 제공 목적으로 자체 개인 정보 보호 정책에 준하여 각 서비스 기술을 통해 앱 사용자에 대한 데이터를 수집할 수 있습니다. 각 서비스들은 개인을 식별할 수 없도록 처리된 정보를 사용합니다. 자세한 정보는 구글 사용 정책을 참고하시기 바랍니다.
        </Typography>
        <Typography>
          제3자의 서비스에서 수집하는 정보는 각 서비스가 사용하는 저장소에만 수집, 저장되며 본 서비스에서는 별도의 장소에 저장하지 않습니다. 앱 개발자는 본 서비스 제공을 위해 사용자와 관련된 그 어떠한 정보도 수집하지 않으므로 제3자의 서비스가 수집하는 개인 식별 정보와 개인 식별 정보에 해당하지 않는 정보를 통합하지 않습니다.
        </Typography>
        <Typography>
          폰트폰트는 고객문의, 이벤트 및 프로모션 진행 시 이용자의 별도 동의를 얻어 제공합니다.
        </Typography>
      </Box>

      <Box sx={{ mb: 1, color: 'black', fontSize: '12px' }}>
        <Typography>
          2. 개인정보의 수집 및 이용목적
        </Typography>
        <Typography>
          폰트폰트는 개인정보를 수집하지 않습니다.
        </Typography>
        <Typography>
          제3자의 서비스에서 수집하는 정보는 다음과 같이 활용됩니다.
        </Typography>
        <Box sx={{ pl: 2, mb: 2 }}>
          <Typography>① Firebase, Analytics 서비스</Typography>
          <Typography>• 정보 수탁자: Google</Typography>
          <Typography>• 위탁하는 업무의 내용: 앱 및 서비스에 대한 사용 통계, 오류 보고 확인</Typography>
          <Typography >• 정보 수탁자의 개인정보 처리방침: https://policies.google.com/privacy?gl=US&hl=ko</Typography>
        </Box>
        <Box sx={{ pl: 2, mb: 2 }}>
          <Typography>② 고객문의</Typography>
          <Typography >• 폰트폰트 고객 문의 처리 시: 이메일 수집</Typography>
        </Box>
        <Box >
          <Typography>③ 별도의 이벤트 진행</Typography>
          <Typography >• 폰트폰트에서 주최하는 이벤트 진행 시: 이름, 연락처 수집</Typography>
        </Box>
      </Box>

      <Box sx={{ mb: 1, color: 'black', fontSize: '12px' }}>
        <Typography>
          3. 개인정보 제공 및 공유
        </Typography>
        <Typography>
          폰트폰트는 개인정보를 공유 및 제3자에게 제공하지 않습니다.
        </Typography>
      </Box>

      <Box sx={{ mb: 1, color: 'black', fontSize: '12px' }}>
        <Typography>
          4. 개인정보처리의 위탁
        </Typography>
        <Typography>
          폰트폰트는 보유할 개인정보를 수집, 이용, 보유하지 않습니다.
        </Typography>
      </Box>

      <Box sx={{ mb: 1, color: 'black', fontSize: '12px' }}>
        <Typography>
          5. 개인정보의 보유 및 이용 기간
        </Typography>
        <Typography>
          폰트폰트는 보유할 개인정보가 없습니다.
        </Typography>
        <Typography>
          - 고객문의, 설문조사, 이벤트 등의 목적을 위하여 수집한 경우: 고객문의 처리 및 설문조사, 이벤트 종료 1개월 후 즉시 파기
        </Typography>
      </Box>

      <Box sx={{ mb: 1, color: 'black', fontSize: '12px' }}>
        <Typography>
          6. 개인정보 파기절차 및 방법
        </Typography>
        <Typography>
          폰트폰트는 파기할 개인정보가 없습니다.
        </Typography>
        <Typography>
          - 고객문의, 설문조사, 이벤트 등의 목적을 위하여 수집한 경우: 고객문의 처리 및 설문조사, 이벤트 종료 1개월 후 즉시 파기
        </Typography>
      </Box>

      <Box sx={{ mb: 1, color: 'black', fontSize: '12px' }}>
        <Typography>
          7. 개인정보 수집 및 그 거부에 관한 사항
        </Typography>
        <Typography>
          이용자는 개인정보 수집을 거부할 수 있고 거부 시 고객문의와 이벤트에 참가할 수 없습니다.
        </Typography>
      </Box>

      <Box sx={{ mb: 1, color: 'black', fontSize: '12px' }}>
        <Typography>
          8. 이용자 및 법정대리인의 권리와 그 행사방법
        </Typography>
        <Typography>
          고객 및 법정 대리인은 언제든지 개인정보 보호책임자에게 서면, 전화 또는 이메일을 통해 등록되어 있는 자신의 개인정보를 조회하거나 수정 및 삭제할 수 있습니다.
        </Typography>
        <Typography>
          14세 미만 아동의 경우 해당 이벤트에 참가할 수 없습니다.
        </Typography>
      </Box>

      <Box sx={{ mb: 1, color: 'black', fontSize: '12px' }}>
        <Typography>
          9. 개인정보의 기술적/관리적 보호 대책
        </Typography>
        <Typography>
          고객의 개인정보는 기본적으로 비밀번호에 의하여 보호되며 비밀번호를 포함한 고객의 개인정보는 각종 보안 장치에 의해 보호되는 별도 시스템을 통해 관리됩니다. 아울러 폰트폰트는 고객의 개인정보에 접근할 수 있는 직원을 최소화하고, 전 직원을 대상으로 개인정보보호를 위한 교육을 실시하여 개인정보의 유출로 인한 피해를 막기 위하여 노력하고 있습니다.
        </Typography>
      </Box>

      <Box sx={{ mb: 1, color: 'black', fontSize: '12px' }}>
        <Typography>
          10. 개인정보보호 책임자 및 담당자의 연락처
        </Typography>
        <Box sx={{ pl: 2, mb: 2 }}>
          <Typography>
            1) 귀하께서는 회사의 서비스를 이용하시는 과정에서 발생하는 모든 개인정보보호 관련 민원을 개인정보보호 책임자 혹은 담당부서로 신고하실 수 있습니다. 회사는 이용자들의 신고사항에 대해 신속하고 충분한 답변을 드릴 것입니다.
          </Typography>
          <Typography>2) 개인정보보호 책임자</Typography>
          <Typography>• 이름: 신동근</Typography>
          <Typography>• 소속: ㈜산돌스퀘어</Typography>
          <Typography>• 직책: 대표</Typography>
          <Typography sx={{ pl: 2, mb: 2 }}>• 메일: donggeun@sandoll.co.kr</Typography>

          <Typography>3) 개인정보보호 담당자</Typography>
          <Typography>• 이름: 신동근</Typography>
          <Typography>• 소속: ㈜산돌스퀘어</Typography>
          <Typography>• 직책: 대표</Typography>
          <Typography sx={{ pl: 2, mb: 2 }}>• 메일: donggeun@sandoll.co.kr</Typography>

          <Typography>
            4) 이용자는 개인정보 침해로 인한 구제를 받기 위하여 개인정보 분쟁조정위원회, 한국 인터넷진흥원 개인정보침해센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보 침해의 신고, 상담에 대하여는 아래의 기관에 문의 바랍니다.
          </Typography>
          <Typography>• 개인정보침해신고센터(한국인터넷진흥원 운영) (https://privacy.kisa.or.kr/ 국번없이 118)</Typography>
          <Typography>• 대검찰청 사이버수사과 (http://www.spo.go.kr / 국번없이 1301)</Typography>
          <Typography>• 경찰청 사이버안전국 (http://cyberbureau.police.go.kr / 국번없이 182)</Typography>
          <Typography >• 개인정보분쟁조정위원회 (https://www.kopico.go.kr/)</Typography>
        </Box>
      </Box>

      <Box sx={{ mb: 1, color: 'black', fontSize: '12px' }}>
        <Typography>
          11. 고지의 의무
        </Typography>
        <Typography>
          개인정보처리방침 내용 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일전부터 폰트폰트 홈페이지를 통해 고지할 것입니다.
        </Typography>
      </Box>

      <Box sx={{ color: 'black', fontSize: '12px' }}>
        <Typography>
          [부칙]
        </Typography>
        <Typography>
          - 개인정보 처리방침 시행일자: 2024년 11월 05일
        </Typography>
        <Typography>
          - 개인정보 처리방침 변경공고일자: 2024년 11월 05일
        </Typography>
      </Box>
    </Box>
  );
};

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Privacy = (props?: { mobile?: boolean; hash?: boolean }) => {
  const HASH_KEY = '#privacy';
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (props?.hash === true) {
      const onHashChange = () => setOpen(window.location.hash === HASH_KEY);
      window.addEventListener('hashchange', onHashChange);
      return () => window.removeEventListener('hashchange', onHashChange);
    }
  }, [props]);

  const handleClickOpen = () => {
    if (props?.hash === true) {
      window.location.hash = HASH_KEY;
    } else {
      setOpen(true);
    }
  };

  const handleClose = () => {
    if (props?.hash === true) {
      window.history.back();
    } else {
      setOpen(false);
    }
  };

  return (
    <>
      <Link
        onClick={handleClickOpen}
        style={{ cursor: 'pointer' }}
      >
        개인정보처리방침
      </Link>
      <Dialog
        fullScreen={props?.mobile === true}
        fullWidth={true}
        maxWidth="md"
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        PaperProps={{
          sx: { bgcolor: 'background.paper' }
        }}
      >
        <AppBar
          position="relative"
          elevation={0}
          sx={{ bgcolor: '#4173FA' }}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2 }}>
              개인정보처리방침
            </Typography>
          </Toolbar>
        </AppBar>
        <PrivacyContent />
      </Dialog>
    </>
  );
};

export default Privacy;
