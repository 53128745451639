import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';

interface NoticeBannerProps {
  onShowNotice: () => void;
}

const NoticeBanner = ({ onShowNotice }: NoticeBannerProps) => {
  return (
    <Box
      sx={{
        width: '100%',
        bgcolor: '#4173FA',
        color: 'white',
        py: { xs: 1, sm: 1.5 },
        px: 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        zIndex: 1000,
      }}
    >
      <InfoOutlined sx={{ fontSize: 20, mr: 1 }} />
      <Typography
        variant="body2"
        sx={{
          mr: 2,
          display: 'flex',
          alignItems: 'center',
          fontSize: { xs: '12px', sm: '14px' }
        }}
      >
        폰트폰트 서비스 사명 변경 및 약관 개정 안내
      </Typography>
      <Button
        size="small"
        variant="outlined"
        onClick={onShowNotice}
        sx={{
          color: 'white',
          borderColor: 'white',
          minWidth: 'auto',
          px: 2,
          py: 0.5,
          fontSize: { xs: '11px', sm: '12px' },
          '&:hover': {
            borderColor: 'white',
            bgcolor: 'rgba(255, 255, 255, 0.1)',
          },
        }}
      >
        자세히 보기
      </Button>
    </Box>
  );
};

export default NoticeBanner;
