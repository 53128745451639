import React, { forwardRef, useEffect, useState } from 'react';
import { Dialog, AppBar, Toolbar, IconButton, Typography, Box, Button, Slide } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { TransitionProps } from '@mui/material/transitions';

const NoticeContent = () => {
  return (
    <Box sx={{
      p: 4,
      maxWidth: 800,
      mx: 'auto',
      letterSpacing: 0,
      wordSpacing: 0,
      '& h6': {
        letterSpacing: 0,
        wordSpacing: 0,
        mb: 1.5,
        fontSize: 12,
        fontWeight: 500
      },
      '& p, & li': {
        letterSpacing: 0,
        wordSpacing: 0,
        fontSize: 12,
        lineHeight: 1.6
      }
    }}>
      <Typography variant="h6" sx={{ fontSize: '16px', mb: 3, textAlign: 'center' }}>
        폰트폰트 서비스 사명 변경 및 약관 개정 안내
      </Typography>

      <Box sx={{ mb: 4, color: 'black', fontSize: '12px' }}>
        <Typography sx={{ mb: 3 }}>
          당사의 사명이 ㈜산돌메타랩에서 ㈜산돌스퀘어로 변경됨에 따라 약관이 아래와 같이 개정되어 안내 드립니다. 서비스 이용에 참고하여 주시기 바랍니다.
        </Typography>
      </Box>

      <Box sx={{ mb: 3, color: 'black', fontSize: '12px' }}>
        <Typography variant="h6">
          1. 개정 약관
        </Typography>
        <Box sx={{ pl: 2 }}>
          <Typography>- 서비스 이용약관</Typography>
          <Typography>- 개인정보처리방침</Typography>
        </Box>
      </Box>

      <Box sx={{ mb: 3, color: 'black', fontSize: '12px' }}>
        <Typography variant="h6">
          2. 개정 사안
        </Typography>
        <Box sx={{ pl: 2 }}>
          <Typography>- 사명 변경</Typography>
          <Typography>- 대표자 명의표기 변경</Typography>
          <Typography>- 대표자 변경에 따른 개인정보보호 책임자 및 담당자 정보</Typography>
        </Box>
      </Box>

      <Box sx={{ mb: 4, color: 'black', fontSize: '12px' }}>
        <Typography variant="h6">
          3. 시행일: 2024년 11월 05일 부터
        </Typography>
      </Box>

      <Box sx={{ mb: 3, color: 'black', fontSize: '12px' }}>
        <Typography>
          상세 개정 내용은 폰트폰트 서비스 내 약관을 참고하여 주시기 바랍니다.
        </Typography>
        <Typography sx={{ mt: 2 }}>
          감사합니다.
        </Typography>
      </Box>
    </Box>
  );
};

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface NoticeDialogProps {
  open: boolean;
  onClose: () => void;
  mobile?: boolean;
}

const NoticeDialog = ({ open, onClose, mobile }: NoticeDialogProps) => {
  return (
    <Dialog
      fullScreen={mobile === true}
      fullWidth={true}
      maxWidth="md"
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      PaperProps={{
        sx: { bgcolor: 'background.paper' }
      }}
    >
      <AppBar
        position="relative"
        elevation={0}
        sx={{ bgcolor: '#4173FA' }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }}>
            공지사항
          </Typography>
        </Toolbar>
      </AppBar>
      <NoticeContent />
      <Box sx={{ p: 2, display: 'flex', justifyContent: 'center' }}>
        <Button
          variant="contained"
          onClick={onClose}
          sx={{
            minWidth: 120,
            bgcolor: '#4173FA',
            '&:hover': {
              bgcolor: '#2855D6'
            }
          }}
        >
          확인
        </Button>
      </Box>
    </Dialog>
  );
};

export default NoticeDialog;
