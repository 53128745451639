import React, { forwardRef, useEffect, useState } from 'react';
import { Dialog, AppBar, Toolbar, IconButton, Typography, Link, Slide, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { TransitionProps } from '@mui/material/transitions';
const TermsContent = () => {
  return (
    <Box sx={{
      p: 4,
      maxWidth: 800,
      mx: 'auto',
      letterSpacing: 0,
      wordSpacing: 0,
      '& h6': {
        letterSpacing: 0,
        wordSpacing: 0,
        mb: 1.5,
        fontSize: 12,
        fontWeight: 500
      },
      '& p, & li': {
        letterSpacing: 0,
        wordSpacing: 0,
        fontSize: 12,
        lineHeight: 1.6
      }
    }}>
      <Typography variant="h6" sx={{ fontSize: '16px', mb: 3 }}>
        폰트폰트 이용약관
      </Typography>

      <Box sx={{ mb: 3, color: 'black', fontSize: '12px' }}>
        <Typography variant="h6">
          제1조 목적
        </Typography>
        <Typography variant="h6" sx={{ mb: 2, color: 'black', fontSize: '12px' }}>
          본 약관은 ㈜산돌스퀘어(이하 '회사')이 운영하는 카메라 애플리케이션인 '폰트폰트'(이하 '본 서비스')의 이용과 관련하여 본 서비스를 이용하는 고객과 회사간에 본 서비스 이용에 대한 조건을 규정함을 목적으로 합니다.
        </Typography>
      </Box>

      <Box sx={{ mb: 3, color: 'black', fontSize: '12px' }}>
        <Typography variant="h6">
          제2조 용어의 정의
        </Typography>
        <Typography variant="h6">
          (1) 본 약관에서 사용하는 용어의 정의는 다음 각 호와 같습니다.
          <Box variant="h6" sx={{ pl: 2, my: 1 }}>
            <Typography sx={{ mb: 1 }}>
              ① 고객: 본 약관에 따라 회사와 이용계약을 체결하고 회사가 제공하는 본 서비스를 이용하는 개인이나 법인 고객. 필요에 따라 '이용자'라고도 합니다.
            </Typography>
            <Typography>
              ② 애플리케이션: 본 서비스를 위해 회사가 제공하는 스마트폰 및 태블릿 단말기용 애플리케이션 소프트웨어 '폰트폰트'을 말합니다.
            </Typography>
          </Box>
        </Typography>
        <Typography>
          (2) 본 약관에서 사용하는 용어의 정의는 1항에서 정하는 것을 제외하고는 관계법령에서 정하는 바에 의합니다. 관계법령에서 정하지 않는 것은 일반적인 상관례에 의합니다.
        </Typography>
      </Box>

      <Box sx={{ mb: 3, color: 'black', fontSize: '12px' }}>
        <Typography variant="h6">
          제3조 약관의 효력 및 변경
        </Typography>
        <Typography>
          (1) 본 약관은 서비스 이용계약의 성격상 본 서비스 이용을 위한 애플리케이션 내에 본 약관을 명시하고, 고객이 제5조에 따른 절차를 이행함으로써 효력이 발생하게 됩니다.
        </Typography>
        <Typography>
          (2) 고객은 수시로 사이트를 방문하거나 배너 창을 확인하여 본 약관의 변경에 대하여 확인할 주의 의무가 있습니다.
        </Typography>
        <Typography>
          (3) 회사는 관계 법령 또는 상관습에 위배하지 않는 범위에서 본 약관을 개정할 수 있습니다.
        </Typography>
        <Typography>
          (4) 회사는 필요하다고 인정되는 경우 본 약관을 변경할 수 있습니다. 회사는 본 약관이 변경되는 경우 변경된 약관의 내용과 적용일을 정하여 적용일 7일전 애플리케이션 내에 공시합니다. 변경된 약관은 공시하거나 고지한 적용일로부터 효력이 발생합니다.
        </Typography>
        <Typography>
          (5) 고객은 변경된 약관에 대해 거부할 권리가 있습니다. 본 약관의 변경에 대해 이의가 있는 고객은 본 서비스 이용을 중단하고 스마트폰이나 태블릿 단말기로부터 삭제하여 이용계약을 해지할 수 있습니다. 회사가 변경된 약관을 공시 또는 고지하면서 고객에게 기간 내에 의사표시를 하지 않으면 변경된 약관에 동의하는 의사표시가 표명된 것으로 본다는 뜻을 알렸음에도 고객이 명시적으로 거부의사를 표명하지 않은 경우 고객이 변경된 약관에 동의한 것으로 간주합니다.
        </Typography>
      </Box>

      <Box sx={{ mb: 3, color: 'black', fontSize: '12px' }}>
        <Typography variant="h6">
          제4조 약관 외 준칙
        </Typography>
        <Typography>
          (1) 본 약관에 명시되지 않은 사항은 정보통신망 이용촉진 및 정보보호 등에 관한 법률과 같은 관계법령 또는 회사가 정한 서비스의 운영정책 등에서 정하는 바에 의합니다.
        </Typography>
        <Typography>
          (2) 회사는 필요한 경우 본 서비스 이용과 관련된 운영정책을 정하여 이를 제3조 제1항의 방법에 의하여 공지할 수 있습니다.
        </Typography>
      </Box>

      <Box sx={{ mb: 3, color: 'black', fontSize: '12px' }}>
        <Typography variant="h6">
          제5조 서비스 이용계약의 체결
        </Typography>
        <Typography>
          (1) 서비스 이용계약은 고객의 스마트폰 및 태블릿 단말기에서 본 애플리케이션을 실행하고 약관의 동의 여부에 대한 질의에 "동의"버튼을 누르면 본 약관에 동의하는 것으로 간주하여 이용신청을 진행하고 회사가 이러한 신청에 대하여 승낙함으로써 체결됩니다.
        </Typography>
        <Typography>
          (2) 회사는 고객의 이용 신청에 대하여 이용을 승낙함을 원칙으로 합니다.
        </Typography>
        <Typography>
          (3) 고객이 본 약관에 동의함은 본 서비스와 관련하여 회사에서 제공하거나 제공할 모든 서비스에 동의하는 것으로 봅니다.
        </Typography>
        <Typography>
          (4) 고객은 본 서비스의 이용권을 제삼자에게 양도하거나 대여할 수 없습니다.
        </Typography>
      </Box>

      <Box sx={{ mb: 3, color: 'black', fontSize: '12px' }}>
        <Typography variant="h6">
          제6조 서비스의 제공
        </Typography>
        <Typography>
          (1) 업무상 또는 기술상 특별한 지장이 없는 한 회사는 본 서비스의 이용시간을 연중무휴 1일 24시간을 원칙으로 합니다. 다만 시스템 정기점검, 서버의 증설 및 교체, 새로운 내용의 추가, 각종 버그 패치, 새로운 서비스로의 교체 등 운영상 필요한 경우 일정기간 동안 본 서비스를 일시 중지시킬 수 있으며, 이러한 경우 회사는 그 내용 및 시간을 애플리케이션 내에 공지합니다. 다만, 회사가 사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.
        </Typography>
        <Typography>
          (2) 본 서비스의 이용 이력이나 이용권은 앱을 스마트폰이나 태블릿 단말기로부터 삭제한 시점에 소멸하며 또한 다른 단말기에서 계속해서 쓸 수 없습니다.
        </Typography>
        <Typography>
          (3) 회사는 운영상 또는 기술상의 필요에 따라 제공하고 있는 서비스를 변경할 수 있습니다.
        </Typography>
        <Typography>
          (4) 회사는 다음 각 호에 해당하는 경우 본 서비스의 전부 또는 일부를 제한하거나 중단할 수 있습니다.
        </Typography>
        <Box sx={{ pl: 2, mb: 1 }}>
          <Typography sx={{ mb: 0.5 }}>① 전시, 사변, 천재지변 또는 국가비상사태 등 불가항력적인 사유가 있는 경우</Typography>
          <Typography sx={{ mb: 0.5 }}>② 정전, 제반 서비스의 장애 또는 이용 량의 폭주 등으로 정상적인 서비스 이용에 지장이 있는 경우</Typography>
          <Typography sx={{ mb: 0.5 }}>③ 서비스용 설비의 보수 등 공사로 인한 부득이한 경우</Typography>
          <Typography>④ 회사의 제반 사정으로 서비스를 할 수 없는 경우</Typography>
        </Box>
        <Typography>
          (5) 회사는 제1항부터 제4항까지의 사유에 의해 본 서비스가 변경되거나 중지되어 발생하는 문제에 대해서 책임을 지지 않습니다.
        </Typography>
      </Box>

      <Box sx={{ mb: 3, color: 'black', fontSize: '12px' }}>
        <Typography variant="h6">
          제7조 정보의 제공 및 광고의 게재
        </Typography>
        <Typography>
          (1) 회사는 본 서비스 등을 유지하기 위하여 광고를 게재할 수 있으며, 고객은 서비스 이용 시 노출되는 광고게재에 대하여 동의합니다.
        </Typography>
        <Typography>
          (2) 회사가 제공하는, 제3자가 주체인, 제1항의 광고에 고객이 참여하거나 교신 또는 거래를 함으로써 발생하는 손실 혹은 손해에 대해서 회사는 어떠한 책임도 부담하지 않습니다.
        </Typography>
        <Typography>
          (3) 회사는 서비스 개선 및 고객 대상 서비스 소개 등을 위한 목적으로 고객 개인에 대한 추가정보를 요구할 수 있으며, 요청에 대해 고객은 승낙하여 추가 정보를 제공하거나 거부할 수 있습니다.
        </Typography>
        <Typography>
          (4) 회사는 고객의 사전 동의 하에 고객으로부터 수집한 개인정보를 활용하여 제1항의 광고 및 제3항의 정보 등을 제공하는 경우 SMS(LMS), 스마트폰 알림 (Push 알림), 이메일을 활용하여 발송할 수 있으며, 고객은 원하지 않을 경우 언제든지 수신을 거부할 수 있습니다.
        </Typography>
      </Box>

      <Box sx={{ mb: 3, color: 'black', fontSize: '12px' }}>
        <Typography variant="h6">
          제8조 고객의 의무
        </Typography>
        <Typography>
          (1) 고객은 회사에서 제공하는 서비스를 이용함에 있어서 본 약관에서 정하고 있는 사항 및 관련 법률규정을 준수하여야 합니다. 또한 고객은 회사의 업무에 방해가 되는 행위를 하여서는 안 됩니다.
        </Typography>
        <Typography>
          (2) 고객은 본 서비스에서 이루어진 행위 및 그 결과에 대하여 일체의 책임을 지게 됩니다. 고객이 본 서비스를 이용하면서 촬영한 사진의 저장 및 백업은 고객이 직접 해야 합니다. 또한 회사는 고객이 촬영한 사진이 고객의 스마트폰 및 태블릿 단말기에 정상적으로 저장되는 것을 보증하지 않습니다.
        </Typography>
        <Typography>
          (3) 고객은 회사가 제공하는 서비스를 목적 이외의 용도로 이용하거나 다음 각 호에 해당하는 행위를 하여서는 안 됩니다.
        </Typography>
        <Box sx={{ pl: 2, mb: 1 }}>
          <Typography sx={{ mb: 0.5 }}>① 회사의 서비스 정보를 이용하여 얻은 정보를 회사의 사전 승낙 없이 복제 또는 유통시키거나 상업적으로 이용하는 행위</Typography>
          <Typography sx={{ mb: 0.5 }}>② 회사가 제공하는 서비스 이용방법에 의하지 않고 비정상적인 방법으로 본 서비스를 이용하거나 시스템에 접근하는 행위</Typography>
          <Typography sx={{ mb: 0.5 }}>③ 회사의 직원이나 운영자를 가칭하거나 사칭하여 또는 타인의 명의를 도용하여 글을 게시하거나 메일을 발송하는 행위</Typography>
          <Typography sx={{ mb: 0.5 }}>④ 타인으로 가장하는 행위 및 타인과의 관계를 허위로 명시하는 행위</Typography>
          <Typography sx={{ mb: 0.5 }}>⑤ 자신 또는 타인에게 재산상의 이익을 주거나 타인에게 손해를 가할 목적으로 허위의 정보를 만들어 유통시키는 행위</Typography>
          <Typography sx={{ mb: 0.5 }}>⑥ 타인의 명예를 손상시키거나 불이익을 주는 것 등을 목적으로 하는 행위, 면식이 없는 인물과의 만남과 교제를 목적으로 하는 행위, 기타 본 서비스가 미리 정한 이용 목적과 다른 목적으로 본 서비스를 이용하는 행위</Typography>
          <Typography sx={{ mb: 0.5 }}>⑦ 회사 또는 제3자의 저작권 등 기타 권리를 침해하는 행위</Typography>
          <Typography sx={{ mb: 0.5 }}>⑧ 수치심이나 혐오감 또는 공포심을 일으키는 정보, 공공질서 및 미풍양속에 위반되는 내용을 포함하는 정보를 만들어서 유통시키는 행위</Typography>
          <Typography sx={{ mb: 0.5 }}>⑨ 본 서비스와 관련된 설비의 오 동작이나 정보 등의 파괴 및 혼란을 유발시키는 컴퓨터 소프트웨어, 하드웨어, 전기통신 장비의 정상적인 가동을 방해, 파괴할 목적으로 고안된 소프트웨어 바이러스, 기타 다른 컴퓨터 코드, 파일, 프로그램을 포함하고 있는 자료를 등록•판매•게시하거나 E-mail로 발송하는 행위</Typography>
          <Typography sx={{ mb: 0.5 }}>⑩ 기타 부적당하다고 당사가 판단한 행위</Typography>
        </Box>
        <Typography>
          (4) 고객은 관계 법령, 본 약관의 규정, 이용안내 및 서비스상에 공지한 주의사항, 회사가 통지하는 사항 등을 준수하여야 하며, 기타 회사의 업무에 방해되는 행위를 하여서는 아니 됩니다.
        </Typography>
        <Typography>
          (5) 고객은 애플리케이션 내 공지사항 및 본 약관의 수정사항을 수시로 확인하여야 합니다.
        </Typography>
        <Typography>
          (6) 고객의 본 서비스 이용에 의하여 회사가 제삼자로부터 클레임을 받는 경우 등의 직접적 혹은 간접적인 손해(변호사 비용 부담 포함)를 입었을 경우, 고객은 당사의 요구에 따라 즉시 이를 보상해야 합니다.
        </Typography>
      </Box>

      <Box sx={{ mb: 3, color: 'black', fontSize: '12px' }}>
        <Typography variant="h6">
          제9조 회사의 의무
        </Typography>
        <Typography>
          (1) 회사는 관련법과 본 약관이 금지하는 행위를 하지 않으며, 특별한 경우를 제외하고는 본 서비스를 중단 없이 안정적으로 운영하여야 합니다.
        </Typography>
        <Typography>
          (2) 회사는 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 통신비밀보호법, 전기통신사업법 등 본 서비스의 운영, 유지와 관련한 법규를 준수합니다.
        </Typography>
      </Box>

      <Box sx={{ mb: 3, color: 'black', fontSize: '12px' }}>
        <Typography variant="h6">
          제10조 면책 조항
        </Typography>
        <Typography>
          (1) 회사는 전시, 사변, 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다.
        </Typography>
        <Typography>
          (2) 회사는 고객의 귀책사유로 인한 본 서비스의 이용장애에 대하여 책임을 지지 않습니다.
        </Typography>
        <Typography>
          (3) 회사는 고객 상호간 또는 고객과 제3자 상호간에 본 서비스를 매개로 발생한 분쟁에 대해서는 개입할 의무가 없으며 이로 인한 손해를 배상할 책임이 없습니다.
        </Typography>
        <Typography>
          (4) 고객이 자신의 개인정보를 타인에게 유출 또는 제공함으로써, 발생하는 피해에 대해서 회사는 일절 책임을 지지 않습니다.
        </Typography>
        <Typography>
          (5) 회사는 고객의 디바이스 관리소홀, 디바이스 부정사용 등에 대한 책임이 면제되며 디바이스 환경으로 인하여 발생하는 제반 문제 또는 회사의 귀책사유가 없는 네트워크 환경으로 인하여 발생하는 문제에 대해서는 책임이 면제됩니다.
        </Typography>
        <Typography>
          (7) 본 서비스 화면에서 링크, 배너 등을 통하여 연결된 회사(이하 피연결회사)와 회원 간에 이루어진 거래에 회사는 개입하지 않으며, 해당 거래에 대하여 책임을 지지 않습니다.
        </Typography>
      </Box>

      <Box sx={{ mb: 3, color: 'black', fontSize: '12px' }}>
        <Typography variant="h6">
          제11조 재판권 및 준거법
        </Typography>
        <Typography>
          (1) 회사와 고객은 서비스와 관련하여 발생한 분쟁을 원활하게 해결하기 위하여 필요한 노력을 합니다.
        </Typography>
        <Typography>
          (2) 서비스 이용으로 발생한 분쟁에 대해 소송이 제기되는 경우 법령에 정한 절차에 따른 법원을 관할 법원으로 합니다.
        </Typography>
        <Typography>
          (3) 회사와 고객 간에 제기된 소송에는 대한민국 법을 적용합니다.
        </Typography>
      </Box>

      <Box sx={{ mt: 4, color: 'black', fontSize: '12px' }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          [부칙]
        </Typography>
        <Typography>
          본 약관은 2024년 11월 05일부터 시행합니다.
        </Typography>
        <Typography>
          본 약관은 2022년 3월 1일부터 시행합니다.
        </Typography>
      </Box>
    </Box>
  );
};

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Terms = (props?: { mobile?: boolean; hash?: boolean }) => {
  const HASH_KEY = '#terms';
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (props?.hash === true) {
      const onHashChange = () => setOpen(window.location.hash === HASH_KEY);
      window.addEventListener('hashchange', onHashChange);
      return () => window.removeEventListener('hashchange', onHashChange);
    }
  }, [props]);

  const handleClickOpen = () => {
    if (props?.hash === true) {
      window.location.hash = HASH_KEY;
    } else {
      setOpen(true);
    }
  };

  const handleClose = () => {
    if (props?.hash === true) {
      window.history.back();
    } else {
      setOpen(false);
    }
  };

  return (
    <>
      <Link
        onClick={handleClickOpen}
        style={{ cursor: 'pointer' }}
      >
        서비스 이용약관
      </Link>
      <Dialog
        fullScreen={props?.mobile === true}
        fullWidth={true}
        maxWidth="md"
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        PaperProps={{
          sx: { bgcolor: 'background.paper' }
        }}
      >
        <AppBar
          position="relative"
          elevation={0}
          sx={{ bgcolor: '#4173FA' }}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2 }}>
              서비스 이용약관
            </Typography>
          </Toolbar>
        </AppBar>
        <TermsContent />
      </Dialog>
    </>
  );
};

export default Terms;
